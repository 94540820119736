<template>
    <div>
        <b-alert show variant="warning" class="text-center">
            {{ this.type === 'edit' ? 
                'Po spremembi oglasa, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe oglasa na seznamu niso vidne, poskusite osvežiti spletno stran.' :
                'Po oddaji oglasa, je potrebnih nekaj sekund, da je oglas prikazan na seznamu. Če oglasa na seznamu ne vidite, poskusite osvežiti spletno stran.'
            }}
        </b-alert>
        <b-form-group>
            <b-form-checkbox switch v-model="object.active">Aktivno</b-form-checkbox>
        </b-form-group>
        <b-row>
            <b-col>
                <b-form-group label="Naprava">
                    <validation-provider #default="{ errors }" name="naprava" rules="required">
                        <b-row>
                            <span class="ml-1" :key="index" v-for="(item, index) in deviceTypes">
                                <b-form-checkbox v-model="object.device_type" name="device" :value="item.id">{{item.title}}</b-form-checkbox>
                            </span>
                        </b-row>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Tip strani">
                    <validation-provider #default="{ errors }" name="tip strani" rules="required">
                        <b-row>
                            <span class="ml-1" :key="index" v-for="(item, index) in pageTypes">
                                <b-form-checkbox v-model="object.page_type" name="page" :value="item.id">{{item.title}}</b-form-checkbox>
                            </span>
                        </b-row>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group label="Začetek prikazovanja">
                    <validation-provider #default="{ errors }" name="začetek prikazovanja" rules="required">
                        <flat-pickr class="form-control" v-model="object.show_from" :config="flatpickrConfig" placeholder="ZAČETEK PRIKAZOVANJA"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group label="Konec prikazovanja">
                    <validation-provider #default="{ errors }" name="konec prikazovanja" rules="required">
                        <flat-pickr class="form-control" v-model="object.show_until" :config="flatpickrConfig" placeholder="KONEC PRIKAZOVANJA"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group label="Spletni naslov, kam vodi oglas ob kliku">
                    <validation-provider #default="{ errors }" name="url" rules="required">
                        <b-form-input v-model="object.url" placeholder="URL"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group v-if="type === 'edit'" label="Slika">
                    <b-form-file v-model="image" placeholder="SLIKA" browse-text="Prebrskaj" accept="image/jpeg, image/png, image/gif"/>
                </b-form-group>
                <b-form-group v-else label="Slika">
                    <validation-provider #default="{ errors }" name="slika" rules="required">
                        <b-form-file v-model="image" placeholder="SLIKA" browse-text="Prebrskaj" accept="image/jpeg, image/png, image/gif"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import { required } from '@validations'
    import flatPickr from 'vue-flatpickr-component'
    import {BAlert, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow, BFormFile} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            flatPickr,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BAlert,
            BFormFile
        },
        model: {
            prop: 'model',
            event: 'input'
        },
        props: {
            model: {
                type: Object,
                default: null
            },
            value: {
                default: null
            },
            type: {
                type: String
            }
        },
        data() {
            return {
                flatpickrConfig: { altInput: true, dateFormat: 'Z', altFormat: 'd. m. Y', locale: { firstDayOfWeek: 1}},
                deviceTypes: [
                    {id:'0', title: 'SPLET'},
                    {id:'1', title: 'MOBILNI'}
                ],
                pageTypes: [
                    {id:'0', title: 'DOMOV'},
                    {id:'1', title: 'NOVICE'},
                    {id:'2', title: 'NEPREMIČNINE'},
                    {id:'3', title: 'MALI OGLASI'},
                    {id:'4', title: 'SPLETNA TRGOVINA'}
                ],
                image: null,
                valid: true,
                required
            }
        },
        methods: {
            async uploadImageToAPI() {
                if (this.image === null) return true
                
                const thisIns = this
                const formData = new FormData()
                formData.append('file', this.image)
                await this.$http.post('/api/management/v1/image/', formData, {headers: {'Content-Type': 'multipart/form-data'}
                }).then(function(response) {
                    thisIns.object.main_photo = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                    thisIns.valid = false
                })
                return this.valid
            }
        },
        computed: {
            object: {
                get() {
                    return this.model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>

<style scoped>

</style>