var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"warning"}},[_vm._v(" "+_vm._s(this.type === 'edit' ? 'Po spremembi oglasa, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe oglasa na seznamu niso vidne, poskusite osvežiti spletno stran.' : 'Po oddaji oglasa, je potrebnih nekaj sekund, da je oglas prikazan na seznamu. Če oglasa na seznamu ne vidite, poskusite osvežiti spletno stran.')+" ")]),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.active),callback:function ($$v) {_vm.$set(_vm.object, "active", $$v)},expression:"object.active"}},[_vm._v("Aktivno")])],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Naprava"}},[_c('validation-provider',{attrs:{"name":"naprava","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',_vm._l((_vm.deviceTypes),function(item,index){return _c('span',{key:index,staticClass:"ml-1"},[_c('b-form-checkbox',{attrs:{"name":"device","value":item.id},model:{value:(_vm.object.device_type),callback:function ($$v) {_vm.$set(_vm.object, "device_type", $$v)},expression:"object.device_type"}},[_vm._v(_vm._s(item.title))])],1)}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Tip strani"}},[_c('validation-provider',{attrs:{"name":"tip strani","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',_vm._l((_vm.pageTypes),function(item,index){return _c('span',{key:index,staticClass:"ml-1"},[_c('b-form-checkbox',{attrs:{"name":"page","value":item.id},model:{value:(_vm.object.page_type),callback:function ($$v) {_vm.$set(_vm.object, "page_type", $$v)},expression:"object.page_type"}},[_vm._v(_vm._s(item.title))])],1)}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Začetek prikazovanja"}},[_c('validation-provider',{attrs:{"name":"začetek prikazovanja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatpickrConfig,"placeholder":"ZAČETEK PRIKAZOVANJA"},model:{value:(_vm.object.show_from),callback:function ($$v) {_vm.$set(_vm.object, "show_from", $$v)},expression:"object.show_from"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Konec prikazovanja"}},[_c('validation-provider',{attrs:{"name":"konec prikazovanja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatpickrConfig,"placeholder":"KONEC PRIKAZOVANJA"},model:{value:(_vm.object.show_until),callback:function ($$v) {_vm.$set(_vm.object, "show_until", $$v)},expression:"object.show_until"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Spletni naslov, kam vodi oglas ob kliku"}},[_c('validation-provider',{attrs:{"name":"url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"URL"},model:{value:(_vm.object.url),callback:function ($$v) {_vm.$set(_vm.object, "url", $$v)},expression:"object.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.type === 'edit')?_c('b-form-group',{attrs:{"label":"Slika"}},[_c('b-form-file',{attrs:{"placeholder":"SLIKA","browse-text":"Prebrskaj","accept":"image/jpeg, image/png, image/gif"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1):_c('b-form-group',{attrs:{"label":"Slika"}},[_c('validation-provider',{attrs:{"name":"slika","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"SLIKA","browse-text":"Prebrskaj","accept":"image/jpeg, image/png, image/gif"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }